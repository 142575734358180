"use client";

import {useTwMediaQuery} from "../../../hooks/useTwMediaQuery";
import {Breakpoint} from "../../../constants/breakpoint";
import Button, {ButtonSize, ButtonVariant} from "@components/Button";
import {PracticeId} from "../../../constants/practiceIds";
import {trackNavLinkClick} from "@components/Nav/utils";
import {getLoginRedirectUrl} from "../../../utils/getRedirectUrl";
import Icon from "@components/Icon";
import React, {useState} from "react";
import {Login} from "@components/Nav/Nav";
import {useTranslation} from "@i18n/client";
import {v4} from "uuid";
import {usePathname, useSearchParams} from "next/navigation";
import {buildBookingLink} from "@page-containers/booking/utils/bookingQuery";

import localRegions from "../../../../public/static/data/localRegions.json";

const locationSlugExtractor = /^\/locations\/([^/]+)$/;
const withSlug = (slug?: string) => (region: {slug: string}) => region.slug === slug;

const LoginButton = ({
  activeStyle,
  isNavHovered,
  transitionClass,
  showBookButton,
}: {
  activeStyle: {buttonBackground: string; backgroundColor: string};
  isNavHovered: boolean;
  transitionClass: string;
  showBookButton: boolean;
}) => {
  const i18n = useTranslation();
  const isMd = !useTwMediaQuery(Breakpoint.MD);
  const [flowId] = useState(v4());

  const pathOrNull = usePathname();
  const searchParamsOrNull = useSearchParams();

  if (showBookButton) {
    const maybeLocationOrRegion = pathOrNull?.match(locationSlugExtractor)?.[1];
    const maybeLocation = localRegions.find(withSlug(maybeLocationOrRegion))
      ? null
      : maybeLocationOrRegion;

    const href = buildBookingLink({
      flowId,
      practiceId: PracticeId.CARBON,
      originUrl: encodeURIComponent(`${pathOrNull}?${searchParamsOrNull}`),
      locationSlug: maybeLocation,
    });

    return (
      <>
        <li>
          <Button
            variant={ButtonVariant.GREEN}
            size={ButtonSize.SM}
            href={href}
            onClick={() =>
              trackNavLinkClick(
                "Account",
                "Menu",
                i18n.t("Book an Appointment"),
                i18n.t("Book an Appointment"),
              )
            }
          >
            {isMd ? i18n.t("Book") : i18n.t("Book an Appointment")}
          </Button>
        </li>
        <li className="hidden sm:block">
          <Button
            variant={ButtonVariant.NAKED}
            href={getLoginRedirectUrl()}
            onClick={() => trackNavLinkClick("Account", "Menu", "My Account", "My Account")}
          >
            <Icon
              icon="person-upper-body"
              className={`text-lg leading-none ${
                activeStyle.backgroundColor === "transparent" ? "text-white" : "text-gray-1000"
              } ${transitionClass}`}
            />
          </Button>
        </li>
      </>
    );
  }

  return (
    <li>
      <Login
        isNavHovered={isNavHovered}
        backgroundColor={activeStyle.buttonBackground}
        onClick={() => trackNavLinkClick("Account", "Navbar", "Log In", "Log In")}
      >
        {i18n.t("Log In / Sign Up")}
      </Login>
    </li>
  );
};

export default React.memo(LoginButton);
